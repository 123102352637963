<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <h1 class="text-center">GALLERY</h1>
    <h2 class="pl-4 primary" max-width="500px">Bookstore</h2>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="2"
        class="d-flex child-flex"
        v-for="bookstore in bookstores"
        :key="`${bookstore}`"
      >
        <v-img
          :src="`/img/bookshop/${bookstore}.jpg`"
          height="200"
          width="200"
          contain
        ></v-img>
      </v-col>
    </v-row>

    <h2 class="pl-4 primary" max-width="500px">Hall</h2>
    <v-row>
      <v-col
        cols="12"
        md="2"
        class="d-flex child-flex"
        v-for="hall in halls"
        :key="`${hall}`"
      >
        <v-img
          :src="`/img/hall/${hall}.jpg`"
          height="200"
          width="200"
          contain
        ></v-img>
      </v-col>
    </v-row>

    <h2 class="pl-4 primary" max-width="500px">Offices</h2>
    <v-row class="mb-5">
      <v-col class="d-flex child-flex" v-for="hub in hubs" :key="`${hub}`">
        <v-img
          :src="`/img/hub/${hub}.jpg`"
          height="200"
          width="200"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
export default {
  components: { BreadCrumb },
  data() {
    return {
      bookstores: 18,
      halls: 1,
      hubs: 9,

      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "Gallery",
          disabled: true,
        },
      ],
    };
  },
};
</script>
